<template>
  <v-layout ref="form" v-model="isValid" row wrap tag="v-form">
    <v-flex xs12 sm6 md6>
      <custom-select
        v-model="data.source_point"
        :disabled="isDisabledStartPointSelect"
        :items="pointsStartList"
        label="Пункт отправления"
        :rules="[v => !!v || '']"
        item-text="text"
        return-object
        :placeholder="!pointsStartList.length ? 'Нет доступных пунктов отправления' : ' '"
        @change="onChangeSourcePoint"
      />
    </v-flex>
    <v-flex xs12 sm4 md4>
      <Calendar
        :value="getDateValue('source_point')"
        :min-date="new Date()"
        :rules="[v => !!v || '']"
        label="Дата прибытия ТС в ПО под загрузку (фактическая)"
        @input="handlerCalendar('source_point', $event)"
      />
    </v-flex>
    <v-flex xs12 sm2 md2>
      <custom-input
        :value="timeInputValue('source_point')"
        :rules="timeRules"
        :masks="['##:##']"
        label="Время (местное для ПО)"
        masked
        @input="handlerTime('source_point', $event)"
      />
    </v-flex>
    <v-flex xs12 sm6 md6>
      <custom-select
        v-model="data.destination_point"
        :disabled="isDisabledFinishPointSelect"
        :items="pointsFinishList"
        :rules="[v => !!v || '']"
        label="Пункт назначения"
        :placeholder="!pointsFinishList.length ? 'Нет доступных пунктов назначения' : ' '"
        item-text="text"
        return-object
      />
    </v-flex>
    <v-flex xs12 sm4 md4>
      <Calendar
        :min-date="getDateValue('source_point')"
        :value="getDateValue('destination_point')"
        :error="noValidDestinationDate().date"
        :rules="[v => !!v || '']"
        label="Дата прибытия ТС в ПН под разгрузку (плановая)"
        @input="handlerCalendar('destination_point', $event)"
      />
    </v-flex>
    <v-flex xs12 sm2 md2>
      <custom-input
        :value="timeInputValue('destination_point')"
        :rules="timeRules"
        :masks="['##:##']"
        :error="noValidDestinationDate().time"
        label="Время (местное для ПН)"
        masked
        @input="handlerTime('destination_point', $event)"
      />
    </v-flex>
    <v-flex xs12>
      <custom-input v-model="data.name" label="Наименование груза" />
    </v-flex>
    <v-flex xs12 sm6 md4>
      <custom-input
        v-model="data.loaded_places"
        label="Кол-во загруженных транспортных мест, шт."
        money="integer"
        placeholder="0"
      />
    </v-flex>
    <v-flex xs12 sm6 md4>
      <custom-input
        v-model="data.volume_of_loaded_places"
        placeholder="0.00"
        label="Обьем загруженных транспортных мест, куб. м."
        money="decimal"
      />
    </v-flex>
    <v-flex xs12 sm6 md4>
      <custom-input
        v-model="data.weight_of_loaded_places"
        placeholder="0.00"
        label="Масса загруженных транспортных мест, кг."
        money="decimal"
      />
    </v-flex>
    <v-flex xs12 sm6 md4>
      <custom-input
        v-model="data.cost"
        label="Стоимость груза, руб."
        money="integer"
        placeholder="0"
      />
    </v-flex>
    <template v-if="isShipperFl">
      <v-flex xs12>
        <custom-input v-model="data.description" label="Опись" />
      </v-flex>
    </template>
    <template v-else>
      <v-flex xs12 sm6 md4>
        <custom-input v-model="data.waybill_information" label="Сведение о путевом листе" />
      </v-flex>
      <v-flex xs12>
        <custom-input
          v-model="data.list_of_freight_documents"
          label="Перечень перевозимых документов на груз"
        />
      </v-flex>
      <v-flex xs12>
        <custom-input v-model="data.number_of_seals" label="Номер пломб, установленных на ТС" />
      </v-flex>
    </template>

    <v-flex xs12 class="buttons">
      <custom-button :loading="loading('order/tn/save')" large round color="white" @click="saveTn">
        Сохранить
      </custom-button>
      <custom-button flat color="primary" class="ml-3" @click="close">
        Отмена
      </custom-button>
    </v-flex>
  </v-layout>
</template>

<script>
/* eslint-disable camelcase */
import lightFormat from 'date-fns/lightFormat';
import { mapState, mapGetters, mapActions } from 'vuex';
import CustomSelect from 'common/components/CustomSelect.vue';
import Calendar from 'common/components/form/Calendar';
import set from 'date-fns/set';
import format from 'date-fns/format';
import {
  getHrsMints,
  formatISO,
  createDatetimeWithOffset,
  getUTCDate,
  timeValidationRule
} from 'common/helpers/dateHelper';
import omit from 'lodash.omit';

const createData = infoTn => ({
  source_point: infoTn?.source_point || {},
  destination_point: infoTn?.destination_point || {},
  name: infoTn?.name || '',
  loaded_places: infoTn?.loaded_places || '',
  volume_of_loaded_places: infoTn?.volume_of_loaded_places || '',
  weight_of_loaded_places: infoTn?.weight_of_loaded_places || '',
  cost: infoTn?.cost || '',
  waybill_information: infoTn?.waybill_information || '',
  list_of_freight_documents: infoTn?.list_of_freight_documents || '',
  number_of_seals: infoTn?.number_of_seals || '',
  description: infoTn?.description || ''
});

export default {
  name: 'WayBillForm',
  components: {
    CustomSelect,
    Calendar
  },
  props: {
    infoTn: {
      type: Object,
      default: () => ({})
    },
    newTn: Boolean,
    isShipperFl: Boolean,
    isPinView: Boolean,
    pinList: {
      type: Array,
      default: () => []
    },
    tnPoint: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      point: null,
      isValid: false,
      data: {}
    };
  },
  computed: {
    ...mapState('orders', {
      info: state => state.orderInfo.info,
      currentOrderCid: state => state.orderInfo.currentOrderCid,
      tnList: state => state.orderInfo.tnList
    }),
    ...mapGetters('common', ['loading']),
    ...mapGetters('orders', ['isSimpleRoute', 'isRangeDate']),

    listPoint() {
      return this.info.route.map(({ point }, i) => ({
        point: i + 1,
        contacts: point.contacts,
        address: point.address,
        date: point.date,
        datetime: point.datetime,
        gmtOffset: point.gmtOffset,
        organizationName: point.organizationName,
        text: `${i + 1 + '.'} ${point.text}`,
        time: point.time,
        uid: point.uid
      }));
    },

    timeRules() {
      return [v => !!v || '', v => timeValidationRule(v)];
    },

    pointsStart() {
      return this.listPoint.filter((point, index) => {
        /* В ПО не может быть конечная точка прибытия (ПН) */
        const notLastPoint = index !== this.listPoint.length - 1;
        return notLastPoint;
      });
    },

    pointsFinish() {
      return this.listPoint.filter((point, index) => {
        /* В ПН не может быть начальная точка отправляения (ПО) */
        const notFirstPoint = index !== 0;
        /* Выбранная точка в ПО не может быть в списке ПН-ий */
        const notChoosedPoint = this.choosedSourcePoint?.uid !== point?.uid;
        /* Смотрим есть ли уже в созданных ТН такая пара. */
        const isAlreadyCreated = !!this.createdTnListUIDs.find(
          uids => uids === `${this.choosedSourcePoint?.uid}${point.uid}`
        );
        const isCorrectWay = this.point < point.point;

        return notFirstPoint && notChoosedPoint && !isAlreadyCreated && isCorrectWay;
      });
    },

    flattedTnList() {
      return this.tnList.map(({ documents }) => documents).flat();
    },

    choosedSourcePoint() {
      const { source_point: choosedSourcePoint = {} } = this.data;
      return choosedSourcePoint;
    },

    choosedDestinationPoint() {
      const { destination_point: choosedDestinationPoint = {} } = this.data;
      return choosedDestinationPoint;
    },

    createdTnListUIDs() {
      return this.flattedTnList.length
        ? this.flattedTnList.map(
            ({ source_point, destination_point }) => `${source_point.uid}${destination_point.uid}`
          )
        : [];
    },

    pointsFinishList() {
      return this.newTn ? [...this.pointsFinish] : [...this.listPoint];
    },

    pointsStartList() {
      return this.newTn ? [...this.pointsStart] : [...this.listPoint];
    },

    isDisabledFinishPointSelect() {
      return this.isSimpleRoute || !this.newTn || !this.pointsFinishList.length;
    },

    isDisabledStartPointSelect() {
      return this.isPinView || this.isSimpleRoute || !this.newTn || !this.pointsStartList.length;
    }
  },
  created() {
    this.createDataHandler();
  },
  methods: {
    ...mapActions('common', ['createController']),
    ...mapActions(['addSnackbar']),

    noValidDestinationDate() {
      const fields = {
        time: false,
        date: false
      };

      const sourceDate = this.getDateValue('source_point');
      const destinationDate = this.getDateValue('destination_point');

      if (!sourceDate || !destinationDate) {
        return fields;
      }

      try {
        const sourceLocaleDate = lightFormat(sourceDate, 'yyyy.MM.dd');
        const destinationLocaleDate = lightFormat(destinationDate, 'yyyy.MM.dd');

        fields.time = new Date(sourceDate) > new Date(destinationDate);
        fields.date = new Date(sourceLocaleDate) > new Date(destinationLocaleDate);
      } catch (error) {
        console.error(error);
      }

      return fields;
    },

    getDestinationPoint(sourcePointUID) {
      return (
        this.pointsFinish.find(
          destinationPoint =>
            sourcePointUID !== destinationPoint.uid &&
            !this.createdTnListUIDs.includes(`${sourcePointUID}${destinationPoint.uid}`) &&
            this.point < destinationPoint.point
        ) || {}
      );
    },

    async createDataHandler() {
      if (this.newTn) {
        let [sourcePoint] = this.pointsStart;

        if (this.isPinView) {
          this.pin = sessionStorage.getItem('pin');
          const { point } = this.pinList.find(_ => _.pin === this.pin) || {};
          this.pinPoint = point;

          sourcePoint = this.pointsStart.find(_ => _.point === this.pinPoint);
        }

        this.point = sourcePoint.point;

        /* Ищем ПН который еще не был создан */
        let destinationPoint = this.getDestinationPoint(sourcePoint.uid);
        this.data = createData({
          source_point: sourcePoint,
          destination_point: destinationPoint,
          name: this.info.name
        });
      } else {
        /* Убираем реактивность у объекта infoTn */
        const initializeData = JSON.parse(JSON.stringify(this.infoTn));
        /* Удаляем cuid поле которое формируется автоматически на бэке из-за uid */
        const omittedDestinationPoint = omit(initializeData.destination_point, 'cuid');
        const omittedSourcePoint = omit(initializeData.source_point, 'cuid');
        this.point = this.tnPoint;
        this.data = createData({
          ...initializeData,
          destination_point: omittedDestinationPoint,
          source_point: omittedSourcePoint
        });
      }
    },

    timeInputValue(field) {
      if (this.data[field]?.time) {
        return this.data[field]?.time;
      }

      if (!this.data[field]?.datetime) {
        return '';
      }

      this.data[field].time = lightFormat(new Date(this.data[field]?.datetime), 'HH:mm');
      return this.data[field].time;
    },

    onChangeSourcePoint(sourcePoint) {
      this.point = sourcePoint.point;
      const destinationPoint = this.data.destination_point;

      /* Если мы выбираем ПО и в ПН пусто - сбрасываем текущий ПН */
      if (!this.pointsFinish.length) {
        this.data.destination_point = {};
      }

      const isNotEqualAndExists =
        destinationPoint.uid &&
        sourcePoint.uid !== destinationPoint.uid &&
        this.point < destinationPoint.point;

      if (isNotEqualAndExists) {
        return;
      }

      this.data.destination_point = this.getDestinationPoint(sourcePoint.uid);
    },

    getDateValue(key) {
      const { datetime = '', gmtOffset = '' } = this.data[key] || {};

      if (!datetime || !gmtOffset) {
        return '';
      }

      return createDatetimeWithOffset({ date: datetime, offset: gmtOffset });
    },

    handlerTime(field, time) {
      if (time.includes('_') || !time) {
        return;
      }

      const date = createDatetimeWithOffset({
        date: this.data[field].datetime,
        offset: this.data[field].gmtOffset
      });
      const [hours, minutes] = getHrsMints(time);
      this.data[field].time = time;
      this.data[field].date = formatISO(
        set(date, {
          hours,
          minutes
        }),
        format
      );

      this.setDatetime({ field, value: this.data[field].date });
    },

    handlerCalendar(field, value) {
      const [hours, minutes] = getHrsMints(this.data[field].time);
      this.data[field].date = formatISO(
        set(value, {
          hours,
          minutes
        }),
        format
      );

      this.setDatetime({ field, value: this.data[field].date });
    },

    setDatetime({ field, value }) {
      const { gmtOffset } = this.data[field];
      const hours = getUTCDate(value).getHours() - gmtOffset;
      this.data[field].datetime = formatISO(
        set(getUTCDate(value), {
          hours
        }),
        format
      );
    },

    close() {
      this.$emit('close');
      this.data = createData();
    },

    async saveTn() {
      if (!this.$refs.form.validate() || !this.isValid) {
        this.addSnackbar({ message: 'Заполните все обязательные поля' });
        return;
      }

      const topic = 'order/tn/save';
      const json = {
        cid: this.currentOrderCid,
        point: this.point,
        data: this.data
      };
      if (!this.newTn) {
        json.tn = this.infoTn.tn;
      }
      try {
        await this.createController({ topic, json });
        this.$emit('save-tn');
        this.addSnackbar({ message: 'Транспортная накладная сохранена', type: 'success' });
      } catch (error) {
        console.error(`Ошибка в запросе ${topic}`, error);
        this.addSnackbar({ message: 'Ошибка на сервере' });
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import '~common/assets/styles/variables.scss';

.buttons {
  margin: 0 0 12px 0;
  > button {
    margin-left: 0;
  }
}

.w-100 {
  width: 100%;
}
</style>
