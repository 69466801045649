<template>
  <v-select
    ref="select"
    v-bind="$attrs"
    :menu-props="{ nudgeBottom: 48 }"
    :placeholder="placeholder"
    v-on="$listeners"
    @click.native.prevent.stop
  />
</template>

<script>
// TODO: think about slots
export default {
  name: 'CustomSelect',
  props: {
    placeholder: {
      type: String,
      default: ' '
    }
  },
  mounted() {
    const element = this.$refs.select.$el.closest('#windowComponent') || document;
    window.addEventListener('click', this.closeSelect);
    element.addEventListener('scroll', this.closeSelect);
  },
  beforeDestroy() {
    const element = this.$refs.select.$el.closest('#windowComponent') || document;
    window.removeEventListener('click', this.closeSelect);
    element.removeEventListener('scroll', this.closeSelect);
  },
  methods: {
    closeSelect() {
      if (!this.$refs.select) return;
      this.$refs.select.blur();
    }
  }
};
</script>
