<template>
  <div ref="popover" class="calendar">
    <v-date-picker
      :value="dateValue"
      :min-date="new Date(minDate)"
      :max-date="new Date(maxDate)"
      :popover-content-offset="0"
      :show-day-popover="false"
      :theme-styles="themeStyles"
      :mode="range ? 'range' : 'single'"
      is-expanded
      :attributes="[{ contentStyle: { border: '1px solid #009ce9' }, dates: new Date() }]"
      :popover-visibility="popoverVisibility"
      :nav-visibility="range ? 'hidden' : 'focus'"
      title-position="left"
      @input="onChange"
      @popover-did-appear="checkIntersection"
    >
      <template slot-scope="{ updateValue }">
        <div v-if="range" class="calendar__view">
          <custom-input :value="dateValue ? getDate(dateValue.start) : ''" label="С" disabled />
          <custom-input :value="dateValue ? getDate(dateValue.end) : ''" label="По" disabled />
          <custom-button color="primary" large round class="calendar__btn" @click="submit">
            Применить
          </custom-button>
        </div>
        <div class="field">
          <div class="control has-icons-left has-icons-right">
            <custom-input
              slot="activator"
              :value="setDate ? '' : inputValue"
              :label="label"
              :error="error"
              :error-messages="errorMessage"
              :rules="rules"
              :disabled="disabled"
              readonly
              @click="visibility = 'visible'"
              @change="updateValue($event.target.value)"
            >
              <template slot="append">
                <icon
                  :class="{ 'disabled-icon': disabled }"
                  name="calendar"
                  size="16"
                  @click="visibility = 'visible'"
                />
                <slot name="icon" />
              </template>
            </custom-input>
          </div>
        </div>
      </template>
    </v-date-picker>
  </div>
</template>

<script>
import CustomInput from 'common/components/CustomInput';
import moment from 'moment';

export default {
  components: {
    CustomInput
  },
  props: {
    value: [String, Object, Date],
    label: String,
    format: {
      type: String,
      default: 'DD.MM.YYYY'
    },
    error: Boolean,
    setDate: Boolean,
    rules: {
      type: Array,
      default: () => []
    },
    maxDate: {
      type: String,
      default: '2118-01-01'
    },
    minDate: {
      type: [String, Date],
      default: '1930-01-01'
    },
    disabled: Boolean,
    errorMessage: String,
    range: Boolean
  },
  data: () => ({
    visibility: 'hidden',
    currentDate: null
  }),
  computed: {
    inputValue() {
      const date = this.currentDate || this.value;

      if (!date) {
        return '';
      }

      if (date && (date.start || date.end)) {
        const start = moment(date.start).format(this.format);
        const end = moment(date.end).format(this.format);
        return moment(date.start).isSame(date.end) ? start : `${start} - ${end}`;
      }

      return moment(date).format(this.format);
    },
    dateValue() {
      const date = this.currentDate || this.value;
      if (typeof date === 'string') return new Date(moment(date));
      return date;
    },
    isMobile() {
      return window.matchMedia('(max-width: 600px)').matches;
    },
    popoverVisibility() {
      if (this.disabled) return 'hidden';
      return !this.isMobile ? this.visibility : 'focus';
    }
  },
  watch: {
    value(val) {
      this.currentDate = val;
    },

    visibility(value) {
      try {
        const isClosedRangePicker = value === 'hidden' && this.range;

        if (!isClosedRangePicker) {
          return;
        }

        if (!this.value) {
          this.currentDate = null;
          return;
        }

        const isEqual = JSON.stringify(this.value) === JSON.stringify(this.currentDate);

        if (isEqual) {
          return;
        }

        this.currentDate = this.value;
      } catch (error) {
        console.error(error);
      }
    }
  },
  created() {
    document.addEventListener('click', e => {
      if (e.target.closest('.popover-origin, .calendar__view')) return;
      this.visibility = 'hidden';
    });
    this.themeStyles = {
      wrapper: {
        backgroundColor: '#fff',
        border: '0px solid #fff',
        borderRadius: '2px',
        color: 'rgba(0,0,0,.87)',
        boxShadow:
          '0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)',
        textDecoration: 'none'
      },
      header: {
        color: '#fafafa',
        backgroundColor: '#009ce9',
        borderColor: '#009ce9'
      },
      headerTitle: {
        fontSize: '30px',
        textAlign: 'left',
        fontWeight: '500',
        padding: '15px'
      },
      weekdays: {
        color: 'rgba(0,0,0,.38)',
        backgroundColor: '#fff',
        padding: '10px 0',
        fontWeight: '600',
        fontSize: '12px'
      },
      weeks: {
        padding: '5px 0 7px'
      },
      dayCell: {
        padding: '6px',
        color: 'rgba(0,0,0,.87)'
      },
      dayContent: {
        fontSize: '12px',
        fontWeight: '500'
      },
      dayCellNotInMonth: {
        opacity: '1',
        color: '#009ce9'
      }
    };
  },
  methods: {
    submit() {
      this.visibility = 'hidden';
      this.$emit('submit', this.currentDate);
    },
    onChange(e) {
      this.currentDate = e ? e : this.value;
      this.$emit('input', this.currentDate);
      if (this.$vuetify.breakpoint.smAndDown) this.submit();
      if (!this.range) this.visibility = 'hidden';
    },
    getDate(date) {
      return moment(date).format('DD.MM.YYYY');
    },
    checkIntersection() {
      const el = this.$refs.popover;
      const { bottom } = el.getBoundingClientRect();
      const offset = document.body.clientHeight - bottom;
      if (offset < 260) {
        el.style.setProperty('--popover-offset', `${offset - 260 + el.clientHeight}px`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~common/assets/styles/variables.scss';

.calendar {
  --popover-offset: 100%;

  color: $color-main;
  position: relative;
  &__btn {
    margin: auto 0 12px 0;
    min-width: unset !important;
    height: 36px;
  }
  &__view {
    display: none;
    flex-direction: column;
    position: absolute;
    left: 0;
    left: 240px;
    top: var(--popover-offset);
    z-index: 12;
    background: #fff;
    width: calc(100% - 240px);
    min-width: 140px;
    margin: -20px 0 0 auto;
    min-height: 280px;
    max-width: 140px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    padding: 16px 12px 0 6px;
    /deep/ .custom-input {
      flex: unset;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -6px;
      width: 8px;
      min-height: inherit;
      background: #fff;
    }
    @media only screen and (max-width: breakpoint(sm)) {
      display: none !important;
    }
  }
}

.disabled-icon {
  color: $gray-light !important;
}

/deep/ .c-pane {
  min-width: unset !important;
}

/deep/ .c-day-backgrounds {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid #f4f4f4;
    border-radius: 50%;
  }
}
/deep/ .c-day-background {
  background-color: #009ce9 !important;
  opacity: 1 !important;
  height: 28px !important;
  width: 28px !important;
  border-radius: 50% !important;
}
/deep/ .c-day-layer,
/deep/ .c-day-backgrounds,
/deep/ .c-day-background,
/deep/ .c-day-content-wrapper {
  transition: unset !important;
}
/deep/.shift-left,
/deep/.shift-right {
  margin: 0 !important;
}
/deep/ .c-day-content {
  font-size: 12px !important;
  height: 28px !important;
  width: 28px !important;
  margin: 0;
}
/deep/ .c-day-box-left-center,
/deep/ .c-day-box-right-center {
  justify-content: center !important;
}
/deep/ .c-day {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px !important;
  height: 28px !important;
  width: 28px !important;
  min-height: unset !important;
}
/deep/ .c-day-popover {
  flex: unset !important;
}

/deep/ .c-weekday {
  border-radius: 50%;
  background-color: #f4f4f4;
  flex: unset;
  width: 28px;
  height: 28px;
  margin: 2px;
  font-size: 12px;
  &:last-child {
    margin-right: 0;
  }
  &:first-child {
    margin-left: 0;
  }
}

/deep/ .popover-origin .popover-content-wrapper .popover-content.direction-bottom:after {
  display: none;
}

/deep/ .popover-origin {
  top: var(--popover-offset) !important;
  margin-top: -20px;
  min-width: 240px;
  max-width: 240px;
}

/deep/ .c-weekdays {
  padding: 0px 10px 0px 10px !important;
}
/deep/ .c-weeks {
  padding: 0px 4px 10px 8px !important;
}
/deep/ .c-header {
  background-color: transparent !important;
}
/deep/ .c-title {
  padding: 0 !important;
  font-size: 14px !important;
  color: #111111;
  text-transform: capitalize;
}
/deep/ .c-title-layout {
  order: unset !important;
}
/deep/ .c-title-popover,
/deep/ .c-title-anchor,
/deep/ .c-title {
  width: 100%;
  text-align: center !important;
}
/deep/ .popover-origin .popover-content-wrapper .popover-content {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15) !important;
}

/deep/ .c-header .c-arrow-layout .c-arrow path {
  fill: #009ce9 !important;
}
/deep/ .popover-origin.fade-enter-active,
/deep/ .popover-origin.fade-leave-active,
/deep/ .popover-origin.slide-fade-enter-active,
/deep/ .popover-origin.fade-enter,
/deep/ .popover-origin.fade-leave-to,
/deep/ .popover-origin.slide-fade-enter,
/deep/ .popover-origin.slide-fade-leave-to,
/deep/ .popover-origin.slide-fade-leave-active {
  opacity: 1 !important;
  transition: unset !important;
  transform: unset !important;
}

/deep/ .popover-origin + .calendar__view {
  display: flex;
}

/deep/ .c-table tr td {
  border: none;
}
</style>
